import PatchesTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-texture/patches/PatchesTemplate';

import { PATCH_TYPE, BOOLEAN } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/SkinTexture/Patches/PatchesTemplate'
};

const createStory = props => () => ({
  components: { PatchesTemplate },
  storyProps: props,
  wrapStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template: `<div :style='$options.wrapStyles'>
    <patches-template v-bind="$options.storyProps" />
  </div>`
});

export const NoAnswer = createStory({
  patches: BOOLEAN.NO
});

export const YesAnswer = createStory({
  patches: BOOLEAN.YES,
  isPatchesTypeVisible: true,
  patchesType: PATCH_TYPE.VERY_DRY
});
